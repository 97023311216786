.custom-clicked{
  color: white !important;
  background-color: #e35d22 !important;
}
.form-groupp{

}
.form-groupp >.MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 0;
}

.css-i4bv87-MuiSvgIcon-root{
  color:#e35d22 ;
}
.css-ptiqhd-MuiSvgIcon-root{
  color:#e35d22 ;
}
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  color: #FFF;
}
/* free air craft */
.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.aircraft-registration-table-boxShadow .rounded-pill,
.custom-shadow-search .form-check{
  font-size: 0.85rem;
}

.custom-freeTrial-btn{
  color: #fff;
  background-color: #F16722; 
   border-color: #F16722 !important;
   height: 2.375rem !important;
   padding: 0 1rem !important;
   font-size: 0.75rem !important;
   font-weight: 600 !important;
   transition: all .15s ease-in-out;
   animation-timing-function: ease-in-out;
   font-family: Inter, sans-serif;
   font-style: normal;
   font-weight: normal;
   text-decoration: none;
   font-size: 1.0rem;
   line-height: 1.35rem;
   border-radius: 3px;
   height: 3rem;
   letter-spacing: 0px;
   text-align: center;
   padding: 0 1.25rem;
   border: 1px solid;
   display: inline-flex;
   align-items: center;
}
.custom-form-control {
  outline: none;
  margin-bottom: 1rem;
  border: none;
  font-size: 0.85rem;
  border-bottom: 1px solid #e35d22;
  border-radius: 0rem;
  /* padding: 2rem; */
}
.custom-form-control:focus {
  outline: none !important;
  margin-bottom: 1rem !important;
}
.custom-form-check-input:checked {
  border-color: #e35d22 !important;
}
.custom-form-check-input[type="radio"] {
  border-radius: 50% !important;
}
/* free */
.custom-btn-begin-search {
  padding: 0.3rem 2rem;
  border-radius: 5px;
  border: none;
  background-color: #e35d22;
  color: white;

  /* opacity: 0.7; */


  background-color: #e35d22;
  border: none;
  color: white;
  opacity: 1;
}
.custom-btn-begin-search:hover {
  background-color: #e35d22;
  border: none;
  color: white;
  opacity: 1;
}

/* free air craft */
.focusmb:focus{
  margin-bottom: 0 !important;
}
.focusmb::selection{
  margin-bottom: 0 !important;
}

.custom-btn-airmen {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #e35d22;
  background-color: transparent;
  color: black;
  cursor: pointer;
}
.custom-btn-airmen:hover {
  background-color: #e35d22;
  border: 1px solid #e35d22;
  color: white;
}

.custom-btn-aircraft {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid #e35d22;
  background-color: transparent;
  color: black;
  cursor: pointer;
}
.custom-btn-aircraft:hover {
  background-color: #e35d22;
  border: 1px solid #e35d22;
  color: white;
}

/* free */
.custom-btn-begin-search {
  padding: 0.3rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  background-color: #e35d22;
  color: white;
  /* opacity: 0.7; */
}
.custom-btn-begin-search:hover {
  background-color: #e35d22;
  border: none;
  color: white;
  opacity: 1;
}
.custom-btn-begin-search:focus{
  outline: none;
}
.custom-shadow-search {
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 15px; /* Optional: Adds padding inside the shadow */
  background-color: #ffffff; /* Optional: Sets background color */
}
.backgroundImg{
  background-repeat: no-repeat  !important;
  background: url('../../../Assets/HomePageImg/backgroundImg.png') center center fixed !important;
  background-size: cover  !important;
}
.about-Us-Img1{
  background-repeat: no-repeat  !important;
  background: url('../../../Assets/AboutUsImg/AboutUs1Img.png') center center  !important;
  background-size: cover  !important;
}
.about-Us-Img2{
  background-repeat: no-repeat  !important;
  background: url('../../../Assets/AboutUsImg/AboutUs2Img.png') center center !important;
  background-size: cover  !important;
}
.form-check{
  font-size: 1rem;
}
@media screen and (max-width: 600px) {
  /* .form-check{
    font-size: 1.2rem;
  } */

  .custom-btn-airmen {
    font-size: 1.2rem;
  }


  .mart5{
    margin-top: 0px;
    }
}
@media screen and (max-width:425px){
.pmob0{
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
}

@media screen and (min-width: 600px) and (max-width: 900px) {

  .mart5{
    margin-top: 0px;
    }
  /* .form-check{
    font-size: 1.2rem;
  } */
  .custom-btn-begin-search {
    font-size: 1.2rem;
  }
  .custom-btn-airmen {
    font-size: 1.2rem;
  }
}

.aircraft-registration-table-boxShadow{
  border-radius: 0.375rem;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}
.borderless table {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
}
.freeAirmen-td{
  border-radius: 1.40981rem;
  background: rgba(218, 218, 218, 0.25);
}

.custom-downloads-table tr th,
.custom-downloads-table tr td,
.aircraft-registration-table-boxShadow tr th,
.aircraft-registration-table-boxShadow tr td{
  font-size: 0.88rem!important;
transform: scale(1);
}
.freeAirmen-td:hover{
  background: #E35D22;
  color: white;
  transform: scale(1.1);
  transition: ease-in-out 300ms;
}

.nacmaxwid{
  max-width: 47rem !important;
  background: black;
    color: white;
    border: 2px solid #E35D22
}

.nacinput{
  background: white;
    color: black;
}
.nacinput:focus{
  background: white;
    color:black;
}

.nacbor{
  background: black;
  color: white;
  /* border: 2px solid #E35D22; */
  border-radius: 0.2rem;
}

.selected-states.css-1fdsijx-ValueContainer{
  text-align: left !important;
}
.css-d7l1ni-option{
	background:  #E35D22 !important;
}
.selected-states .css-1nmdiq5-menu{
  text-align: left !important;
}
.selected-states .css-1jqq78o-placeholder{
  text-align: left !important;
}
