.ame-boxShadow{

    background: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
/* .form-check-input:checked {
    background-color: #F16722; 
  } */
.amePopup-sub-heading{
    position: absolute;
    left: 17px;
    top: -10px;
    background: white;
    /* padding: 5px; */
    color: #E35D22;
    font-size: 0.78rem;
    font-family: 'Inter',sans-serif;
    font-weight:bold;
    word-wrap: break-word;
}
.amePopup-label{
font-size: 0.9rem;
}
.ame-sub-boxShadow{
    background: #F5F5F5;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.40); border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; 
}
.ame-sub-boxShadow .heading{
    color: black;
font-size: 0.9rem;
font-family: 'Inter',sans-serif;
font-weight: 500;
word-wrap: break-word
}
.tab-transition {
    transition-duration: 2s;
    transition: opacity 0.5s ease; /* Example transition effect */
  }
  .ame-sub-boxShadow .sub-heading{
    font-size: 0.9rem;
  }
  .css-1fdsijx-ValueContainer{
    padding-left: 0px;
  }




@media only screen and (min-width: 320px) and (max-width: 768px) {
  .form-check-input{
    margin-top: 0px !important;
  }
  .exports-Records-btn{
    font-size: 1.15rem !important;
  }
  .ame-sub-boxShadow .sub-heading{
    font-size: 1rem !important; 
  }
  .amePopup-sub-heading{
    top: -6px;
    font-size: 1rem;
  }
  .css-1lczath-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 1.1rem !important;
  }
  
  .text-alignment{
    line-height: 2.4375em !important;
    left: -4px;
  }
}
