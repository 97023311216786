.my-account {
    padding: 0.5rem 1rem;
    position: relative; }
.sub-menu { 
  visibility: hidden; /* hides sub-menu */
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transform: translateY(2em);
  z-index: -1;

  background-color:#000000;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.my-account:focus .sub-menu,
.my-account:focus-within .sub-menu,
.my-account:hover .sub-menu {
  visibility: visible; /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(3%);
  transition-delay: 0s, 0s, 0.2s; /* this removes the transition delay so the menu will be visible while the other styles transition */
}

ul,
ul li { list-style-type: none; padding: 0; margin: 0; }

.custom-dropdown > ul { color: white;  }
.custom-dropdown > ul > li:first-child { border-left: none; }

.my-account {
    display: block;
    opacity: 1;
    width: 100%;
    pointer-events: auto;
    transform: translate(0,);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
}
.menu-item{
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    white-space: nowrap;
    background-color: black;
    border: 0;
    
}
 .menu-item:hover{
    color: #F16722 !important;
        background: rgba(0, 0, 0, .1);
} 



.planelists-dataBase{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 13px;
    text-align: left;
    padding-right: 0.5rem;
    /* padding-left: 0.5rem; */
    color: white;
}



@media screen and (min-width: 300px) and (max-width: 950px) {
    .planelists-dataBase{
        font-size: 1.4rem; 
    }
    .my-account{
        font-size: 1.4rem; 
    }
    .my-account{
        font-size: 1.4rem; 
    }
    .navbar .navbar-nav .dropdown .dropdown-item{
        font-size: 1.4rem;   
    }
    .navbar .navbar-nav .nav-link{
        font-size: 1.4rem;  
    }
  }

@media screen and (min-width: 950px) and (max-width: 1300px) {
    .planelists-dataBase{
        font-size: 0.82rem; 
    }
    .my-account{
        font-size: 0.82rem; 
    }
    .my-account{
        font-size: 0.82rem; 
    }
  }
@media screen and (min-width: 1300px) and (max-width: 1400px) {
    .planelists-dataBase{
        font-size: 0.8rem; 
        font-weight: bold;
    }
    .my-account{
        font-size: 0.8rem; 
    }
    .my-account{
        font-size: 0.8rem; 
    }
  }
  @media screen and (min-width: 1400px) and (max-width: 1550px) {
    .planelists-dataBase{
        font-size: 0.85rem; 
    }
    .my-account{
        font-size: 0.85rem; 
    }
    .my-account{
        font-size: 0.85rem; 
    }
  }
.custom-login-btn{
    color: #fff;
    /* background-color: #F16722; 
     border-color: #F16722 !important; */
     height: 2.375rem !important;
     padding: 0 1rem !important;
     font-size: 0.75rem !important;
     font-weight: 600 !important;
     transition: all .15s ease-in-out;
     animation-timing-function: ease-in-out;
     font-family: Inter, sans-serif;
     font-style: normal;
     font-weight: normal;
     text-decoration: none;
     font-size: 1.0rem;
     line-height: 1.35rem;
     border-radius: 3px;
     height: 3rem;
     letter-spacing: 0px;
     text-align: center;
     padding: 0 1.25rem;
     border: 1px solid;
     display: inline-flex;
     align-items: center;
}
.custom-login-btn:hover{
    border-color: #F16722 !important; 
}
.custom-freeTrial-btn{
    color: #fff;
    background-color: #F16722; 
     border-color: #F16722 !important;
     height: 2.375rem !important;
     padding: 0 1rem !important;
     font-size: 0.75rem !important;
     font-weight: 600 !important;
     transition: all .15s ease-in-out;
     animation-timing-function: ease-in-out;
     font-family: Inter, sans-serif;
     font-style: normal;
     font-weight: normal;
     text-decoration: none;
     font-size: 1.0rem;
     line-height: 1.35rem;
     border-radius: 3px;
     height: 3rem;
     letter-spacing: 0px;
     text-align: center;
     padding: 0 1.25rem;
     border: 1px solid;
     display: inline-flex;
     align-items: center;
}
.navbar-align{
    display: flex;
    justify-content: space-evenly !important;
    width: 100%;
}

.headerFontSize{
font-size: 15px;
}
@media screen and (max-width: 768px){
    .myaccFontSize{
        font-size: 1.4rem;
    }
    .ptn-2{
        padding-top: 1rem;
    }
  
}

/* Initially show the dropdown menu for screens below or equal to 786px */
.dropdown-menu {
    display: block;
  }
  
  /* Hide the dropdown menu for screens above 786px */
  @media (min-width: 787px) {
    .dropdown-menu {
      display: none;
    }
  }
  @media (max-width: 425px) {
	.mob-textsize{
		font-size: 1rem !important;
	}
  }