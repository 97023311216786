/*********************************************************************
	SR Nav Drpopdown 03 (global)
*********************************************************************/
/* p{
  font-size: 1.2rem;
} */
.navbar-nav > .nav-item:not(.dropdown):after,
.navbar-nav .nav-item.active:after {
  background: #f16722 !important;
}
.top-bar ul li a {
  color: #4d4f5c !important;
  padding-right: 0.5rem !important;
}
.navbar-light .navbar-nav .nav-link {
  color: white !important;
}
.navbar .navbar-nav .dropdown .dropdown-item,
.navbar .navbar-nav .nav-link,
.btn-wrapper.btn-primary-wrapper.d-xl-inline a {
  font-size: 13px;
  color: #ffffff;
  font-family: Inter, sans-serif;
}
.btn-wrapper.btn-primary-wrapper.d-xl-inline a:hover {
  background: #f16722;
}
.search,
.show-search,
.search svg {
  color: white !important;
}
input.hs-search-field__input.form-control {
  color: #ffffff;
}
.searchicon,
#icon-search {
  color: white;
}
.results-searchbox {
  background: #edf1f2;
}
.results-searchbox input {
  margin: 10px 0;
}
/*********************************************************************
	Homepage Video Hero
*********************************************************************/
#homeHero .btn-primary-wrapper a.cta_button {
  font-size: 24px;
  font-weight: bold;
  padding: 10px 60px;
  outline: none;
  background-color: #f16722;
  border: none;
  border-radius: 5px;
  /* box-shadow: 0 9px #C34C10; */
  line-height: 1em;
}
#homeHero .btn-primary-wrapper a.cta_button:hover {
  background-color: #f16722;
}
#homeHero .btn-primary-wrapper a.cta_button:active {
  background-color: #f16722;
  box-shadow: 0 5px #c34c10;
  transform: translateY(4px);
}

/*********************************************************************
	Notification Header Styles
*********************************************************************/

.top-bar-text {
  padding: 0.5rem 2rem;
  text-align: center;
  background: black;
  color: white;
}

/*********************************************************************
	Commong H2 Section Headers
*********************************************************************/
.font-inter {
  font-family: "Inter", sans-serif !important;
}
.section-heading {
  /* margin: -1px !important; */
  font-weight: bold;
  font-family: "Inter", sans-serif !important;
}
h2.section-heading:after,
h1.h2:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 100px;
  padding-top: 20px;
  border-bottom: 3px solid #f16722;
}
h4.section-heading:after,
h1.h2:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 100px;
  padding-top: 20px;
  border-bottom: 3px solid #f16722;
}
/*********************************************************************
	Common Hero Header
*********************************************************************/
.sr-hero-01 h1:after {
  content: "";
  display: block;
  margin: 0px auto 30px auto;
  width: 100px; /* padding-bottom: 13px; */
  padding-top: 15px;
  border-bottom: 3px solid #f16722;
}
/*********************************************************************
	Common Hero Button
*********************************************************************/
.sr-hero-01 .btn-primary-wrapper a.cta_button {
  font-size: 18px;
  font-weight: bold;
  padding: 5px 30px;
  outline: none;
  background-color: #f16722;
  border: none;
  border-radius: 5px;
  /* box-shadow: 0 9px #C34C10; */
  line-height: 1em;
}
.sr-hero-01 .btn-primary-wrapper a.cta_button:hover {
  background-color: #f16722;
}
.sr-hero-01 .btn-primary-wrapper a.cta_button:active {
  background-color: #f16722;
  box-shadow: 0 5px #c34c10;
  transform: translateY(4px);
}
/*********************************************************************
	Data Stats
*********************************************************************/
.stats-wrapper h2 {
  font-size: 3.2rem !important;
  line-height: 4rem !important;
  margin-bottom: 0;
}
h2.heading:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 100px;
  padding-top: 20px;
  border-bottom: 3px solid #f16722;
}
/*********************************************************************
	Pricing Page Styles
*********************************************************************/
h4.pricing-card-head:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 40px;
  /* padding-top: 20px; */
  border-bottom: 3px solid #f16722;
}
.sr-cards-grid-02 .cards.row [class*="col-"] {
  padding: 0;
  border: 1rem solid transparent;
}
.sr-cards-grid-02 .card {
  position: relative;
  background-size: cover;
  background-position: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 3.35rem 2.2rem !important;
  /* border: 1px solid #f16722 !important; */
}
.sr-cards-grid-02 .card:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.sr-cards-grid-02 .card-inner {
  position: relative;
  z-index: 1;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sr-cards-grid-02 .card-inner .btn-wrapper {
  margin-top: auto;
}
.sr-cards-grid-02 .card-inner .btn-wrapper {
  margin-top: 1.63rem;
}
.sr-cards-grid-02 .card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

@media (max-width: 768px) {
  .sr-cards-grid-02 .heading br,
  .sr-cards-grid-02 .description br {
    display: none;
  }
  img.navbar-brand-light,
  img.navbar-brand {
    width: 200px !important;
  }
}

/*********************************************************************
	Footer Styles - SR Footer 01 (global)
*********************************************************************/
.sr-footer-01 .sr-footer-nav .hs-menu-depth-1 > a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.sr-footer-01 .sr-footer-nav .hs-menu-depth-1 > a:after {
  content: "";
  display: block;
  margin: 0;
  width: 50px;
  margin-bottom: 9px;
    padding-top: 2px;
  border-bottom: 3px solid #f16722;
}
.sr-footer-01 .sr-footer-nav li a,
.sr-footer-01 .sr-footer-nav p {
  line-height: 1.3em !important;
  font-size: 12px !important;
}
.socialFooter a {
  color: #ffffff !important;
  padding: 0 0 0 5px;
}

.footer-copyright span {
  color: #f16722;
  font-size: 16px;
  padding: 0 10px;
}
@media (min-width: 992px) {
  .sr-tabs-split-screen .pin.loaded.active {
    width: 40% !important;
  }
}

/*********************************************************************
	Conversational Form Styles
*********************************************************************/
.sr-conversational-form-01 form {
  line-height: 1.5em !important;
}

/*********************************************************************
	Blog Table Styles
*********************************************************************/
.blog-table {
  width: 100%;
}
.blog-table td {
  padding: 5px;
  font-size: 11px;
  border: 1px solid #888888;
}

/*********************************************************************
	One Trust Override CSS
*********************************************************************/
#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  background-color: #f16722 !important;
  border-color: #f16722 !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}
#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  color: #f16722 !important;
  border-color: #f16722 !important;
  background-color: #ffffff;
}
#onetrust-banner-sdk.ot-bnr-w-logo .ot-bnr-logo {
  padding: 20px !important;
}
#onetrust-banner-sdk.ot-bnr-w-logo #onetrust-policy {
  margin-left: 100px !important;
}
#ot-sdk-btn-floating .ot-floating-button__front {
  background-color: #f16722 !important;
}
#ot-sdk-btn-floating .ot-floating-button__back {
  background-color: #f16722 !important;
}
/*********************************************************************
	Login
*********************************************************************/
.not-a-member {
  font-size: 0.9rem;
}
.register {
  color: #f16722;
  font-size: 0.9rem;
}
.forgot-password{
  color: #F16722;
  font-size: 0.8rem;
}
/*********************************************************************
	Register
*********************************************************************/
.material-class-border {
  border-color: #f16722 !important;
}

.input-group>.form-control:not(:last-child) {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}



/* ================================================================================= */
/* ================================== Media Query For all screen=================================== */
/* ================================================================================= */

@media (min-width: 300px) and (max-width: 335px) {
 
}
/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium devices (tablets, portrait iPads, 768px and up) */
@media (min-width: 768px) and (max-width: 1023.98px) {

}
/* Portrait orientation iPads */
@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

}

/* Landscape orientation iPads */
@media (min-device-width: 768px) and (max-device-width: 1400px) and (orientation: landscape) {
  /* .heading-media-query{
    font-size: 1.2rem;
  }
  .para-media-query{
    font-size: 1.1rem;
  } */
}
/* Large devices (landscape iPads and desktops, 1024px and up) */
@media (min-width: 1024px) and (max-width: 1199.98px) {
  .heading-media-query{
    font-size: 1.2rem;
  }
  .para-media-query{
    font-size: 1.1rem;
  }
}

/* Extra large devices (large desktops, 1440px and up) */
@media (min-width: 1440px) {
.heading-media-query{
  font-size: 1.2rem;
}
.para-media-query{
  font-size: 1rem;
}

}

/* Extra large devices (large desktops, 1440px and up) */
@media (min-width: 2560px) {

}
