.contact-iframe {
  height: 100%;
  max-height: 60lvh;
  min-height: 60lvh;
}
@media (min-width: 768px) and (max-width: 1366px) {
  .contact-iframe {
    height: 100%;
    max-height: 100vh;
    min-height: 100vh;
  }
}
@media (min-width: 1366px) and (max-width: 1400px) {
  .contact-iframe {
    height: 100%;
    max-height: 80vh;
    min-height: 80vh;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .contact-iframe {
    height: 100%;
    max-height: 100vh;
    min-height: 100vh;
  }
}
#resetReacptcha {
  display: none;
}

.ame-iframe {
  height: 100%;
  width: 100%;
  max-height: 600px;
  min-height: 500px;
}
.ame-iframe-shadow {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
