.dashtext{
    color: white !important;
    /* text-align: center; */
}

.h-100 {
    height: 100% !important;
}
.dash-heading{
    margin: 0%;
}

.themecolor{
    color: #E35D22;
    font-family: "Inter",sans-serif;
}

@media (max-width: 425px) {
    .mobile-container {
      width: 45%;
      margin-left: 0;
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .tab-container {
      /* width: 110%;  */
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .name-wrap {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
.user-form{
   margin-top: -5px!important;
}
