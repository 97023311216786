.custom-heading-border .header::after {
    content: "";
    display: block;
    width:5rem;
    padding-top: 5px;
    border-bottom: 2px solid #f16722;
}
.text-orange{
    color: #f16722;
}
.custom-heading-border hr {
  background-color: #868686;
}
.justify-content{
  text-align: justify;
}
.css-wpqx65-menu{
  z-index: 1 !important;
  }