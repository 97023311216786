/* .sr-footer{
    background-color: black;
} */
/* .form-check-input:checked {
    background-color: orange !important;
  } */
  
  /* .form-check-input:checked::before {
    background-color: orange !important;
    border-color: orange !important;
  } */
  
  .form-check-input:checked:focus::before {
    box-shadow: 0 0 0 1px orange, 0 0 0 3px rgba(255, 165, 0, 0.2) !important;
  
  }
  body{
    background-color: #000000 !important;
  }