.popup {
    width: 500px;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
}
.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: white;
  }

  .popup-heading{
    text-align: center;
  }