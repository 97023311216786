.bg-theme{
  background-color: #F16722;
}
.headingheight{
  height: 22rem;
  border: 1px dashed #FFFFFF !important;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: black;
 
  border-width: 2;

 
  border-radius: 20;
  display: 'flex';
  height: '100%';

  padding: 20;
}
.focusmb:focus{
  margin-bottom: 0 !important;
}
.focusmb::selection{
  margin-bottom: 0 !important;
}
.headingmain{
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
}
.pd{
  /* padding-left:  8% ;
  padding-right:  8% ; */
}
.name{
  color: #F16722 ;
}
.editiconcss{
  max-width: 15px;
}
.mt03{
margin-top: 0.3rem
}
.senddetail{
  font-weight: 600;
  font-size: 1.5rem !important;
}

.themeborder{
  border: 1px solid white!important;
}

.themeborder2{
  border: 1px solid #F16722 !important;
}
.px-15{
  padding: 0px 15px;
}
.custom-orange-border-search {
  background: black;
  color: white;
  border: 2px solid #E35D22;
  border-radius: 4px;
}
.custom-form-fields {
  background: white;
  color: black;
  border-radius: 4px;
}
.whiteborder{
  border: 1px solid white;
}
.bg2-image {
  /* background-image: url('./Template2front.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;padding-bottom: 30%;You may or may not want to specify height depending on your layout */
}
.mtneg2{
  margin-top: -33px;
}
.create-post-card-date .date-input::-webkit-calendar-picker-indicator {
  filter: invert(100%); 
}

@media screen and (min-width: 1150px) {
  .mtNeg{
      margin-top: -170px;
  }
  .mtneg2{
      margin-top: -100px;
  }
  
}

@media  screen and ( min-width:900px) and (max-width: 1150px) {
  .mtNeg{
      margin-top: -100px;
  }
  
  .mtneg2{
      margin-top: -60px; 
  }
}

@media screen and (max-width: 900px) and ( min-width:560px) {
  .mtNeg{
      margin-top: -60px;
  }
  .mtneg2{
      margin-top: -60px;
  }
  
}
@media (max-width: 425px) {
 
}

.data-table-trash{
  background: transparent;
    border: none;
}
.data-table-trash:hover{
  background: rgba(235, 232, 232, 0.774);
}
.data-table-trash:focus{
  outline: none;
}
/* Data table css  */
.p-datatable .p-datatable-thead > tr > th{
  white-space: nowrap;
  background: #F16722;
  color: white;
}
.p-dropdown-item{
  justify-content: center;
}
.p-paginator .p-dropdown {
display: flex;
align-items: center;
}
