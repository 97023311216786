.breadcrumb{
    padding: .75rem 0rem !important;
    font-size: 0.9rem;
    background-color:transparent ;
}
.breadcrumb-item+.breadcrumb-item::before{
    content: "";
    display: inline-block;
    justify-self: center;
    width: 0.8rem;
    height: 0.8rem; 
    background-image: url('/public/images/breadcrumb-icon.png'); 
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 5px 0px 0px;
}
.global{
    color: wheat;
}