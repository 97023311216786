.custom-text-color-orange{
    color: #F16722;
}
.profile-image{
/* max-width: 50%; */
}
.custom-form-shadow{
    border-radius: 8px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.custom-para-outline{
    border: 1px solid #E35D22;
    border-radius: 0.3rem;
    font-weight: 600;
    line-height: 20px;
}
.custom-downloads-thead{
    background: #E35D22;
    color: white;
    border-radius: 10px 10px 0px 0px;
}
.custom-downloads-table{
   border-radius: 10px 10px 0px 0px;
}

/* Payment History */

.payment-history{
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    padding: 0.3rem 0.6rem;
    border:1px solid #E35D22;
    border-radius: 5px;
}
.payment-history option {
    border:1px solid #E35D22;  
}
.privacy-para-header{
    color: #FFF;
    font-family: "Inter",sans-serif;
font-size: 1.3rem;
font-style: normal;
font-weight: 500;
line-height: 20px;
background: #FF7125;
}
.tearm-para-header {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
.custom-para-shadow{
    border-radius: 4px;
background: #FFF;
box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
color: #6D6D6D

}
.unorder-list ul li{
    list-style-type: disc !important; 
}
.custom-blog-outline{
    border: 1px solid #E35D22;
    border-radius: 0.3rem;
}
.blog-para-header{
    color: black;
    font-family: "Inter",sans-serif;
font-size: 1.3rem;
font-style: normal;
font-weight: 600;
}
.feedback-modal-header{
    font-family: "Inter",sans-serif;
    color: black;
    font-size: 1.5rem;
}
.feedback-textarea{
    border-radius: 3.06px;
background: #EEE;
box-shadow: 0px 0px 0px 1.53px rgba(255, 255, 255, 0.50);
border: none;
}
.feedback-input{
    border-bottom: 1px solid  #E35D22 !important;
    border: none;
}
.feedback-input:focus{
outline: none;
}
.personal-profile-boder{
    border-bottom: 1px solid  #FF773A !important;
}
.personal-edit{
    color:  #FF773A;
    cursor: pointer;
}
.personal-edit-img{
    cursor: pointer
}

@media only screen and (min-width: 993px) {
    .personal-info-lable{
        border: 4px solid #FF773A; 
    }
    .ame-lable{
        border: 4px solid #FF773A; 
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .personal-info-lable{
       padding-right: 2rem;
    }
}
@media only screen and (min-width: 577px) and (max-width: 1024px) {
    .profile-image{
        width: 50% ;
    max-width: 64px;
    min-width: 50px;
    }
}
.ame-lable{
    background-color: #FF773A;
    color: white;
    border-radius:0px 5px 5px 0px;
    margin: 0px !important;
    font-size: 1rem;
}
.ame-text-lable{
font-size: 1rem
}
.ame-input{
    border-radius:5px 0px 0px 5px;
}

.personal-info-lable{
    background-color: #FF773A;

    color: white;
    border-radius:5px 0px 0px 5px;
    margin: 0px !important;
    font-size: 1rem;
}
.personal-info-input{
    border-radius:0px 5px 5px 0px;
}
.personal-info-form{
    display: grid;
    gap: 20px;
}
/* .personal-info-form .form-control:disabled {
    background-color: white;
    opacity: 1;
  } */
.custom-save-btn{
    color: white;
    border: 1px solid #FF773A;
    font-size: 1rem;
    background:  #FF773A;
    border-radius: 5px;
    padding: 0.3rem 1.5rem 0.3rem 1.5rem;
}
.custom-save-btn:hover{
    color: white !important;
}
.custom-cancel-btn{
    padding: 0.3rem 0.9rem 0.3rem 0.9rem;
    color: black;
    border: 1px solid #FF773A;
    font-size: 1rem;
    border-radius: 5px;
    background: white;
}
.custom-cancel-btn:hover{
    color: white;
    background:  #FF773A;
}
.custom-cancel-btn:focus{
outline: #FF773A;
}

.global{
    background-color: black !important;
    color: white !important;
}
.payment-history-inputes{
    font-size: 0.88rem;
}

@media (max-width: 768px) {
    /* Add your CSS rules here for screens between 481px and 768px */
    .input-small-screen{
        border-right:0px !important;
        border-left:0px !important;
    }
    .input-small-screen1{
        border-right:0px !important;
    }
  }