.offMarket-box-border{
    background: white;
    border-radius: 5px;
    border:1px solid #F16722  !important;
}

.MuiOutlinedInput-notchedOutline:hover
{
  border-color: #F16722  !important;
}
.MuiOutlinedInput-notchedOutline
{
  border-color: #F16722  !important;
}
.offMarket-box-border:hover{
  background: white;
  border-radius: 5px;
  border:1px solid #F16722  !important;
}
.offMarket-box-border:focus{
  border-color: #F16722  !important; 
}
.offMarket-box-border:hover{
  border:1px solid #F16722  !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #F16722 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline
{
  border-color: #F16722  !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline:hover
{
  border-color: #F16722  !important;
}
/* .form-check-input:checked {
    background-color: #F16722; 
  } */
.offMarket-sub-heading{
    position: absolute;
    left: 17px;
    top: -11px;
    background: white;
    padding: 0px 3px;
    color:#E35D22;
    font-size: 0.78rem;
    line-height: 21px;
    font-weight: bold;
    font-family: 'Inter',sans-serif;
    word-wrap: break-word;
}
.amePopup-label{
font-size: 0.9rem;
}
.ame-sub-boxShadow{
    background: #F5F5F5;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.40); border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; 
}
.ame-sub-boxShadow .heading{
    color: black;
font-size: 0.9rem;
font-family: 'Inter',sans-serif;
font-weight: 500;
word-wrap: break-word
}
.tab-transition {
    transition-duration: 2s;
    transition: opacity 0.5s ease; /* Example transition effect */
  }
  .ame-sub-boxShadow .sub-heading{
    font-size: 0.9rem;
  }

  /* Material Ui over-ride css */
  .css-1nrlq1o-MuiFormControl-root {
width: 100% !important;
  }
  .css-13sljp9{
    width: 100% !important;
  }
  .MuiFormControl-root {
    width: 100% !important;
  }
  .css-7onm7s-MuiInputBase-root-MuiOutlinedInput-root{
padding: 0px;
  }
  .offmarket-output-format{
    display: flex;
    justify-content: space-evenly;
  }

  .css-1lczath-MuiFormLabel-root-MuiInputLabel-root {
    line-height: normal;
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
color: #E35D22;
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #E35D22;
}
  
  @media (max-width: 425px) {
    .mob-textAlignOFF{
      line-height: 2.237em !important;
    }
    .css-1lczath-MuiFormLabel-root-MuiInputLabel-root{
      line-height: 2.237em !important;
      left: -5px !important;
    }

    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
      line-height: 2.237em !important;
      left: -5px !important;
    }

  }

  @media (max-width: 768px) {
    .form-check-input {
      transform: scale(0.75);
    }
    .form-check-label {
      margin-left: 0.5rem;
    }
  }

  /* .form-check-input {
    transform: scale(0.75);
  }
  .form-check-label {
    margin-left: 0.5rem;
  } */
  .offMarketForm .custom-form-sub-heading{
    position: absolute;
    left: 6px;
    top: -13px;
    padding: 0px 5px;
    background: black;
    /* padding: 5px; */
    color: #E35D22;
    font-size: 0.7rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
  }

  .css-1rb7s1c-menu{
    z-index: 1 !important;
  }
  
 .searchPlane .custom-form-sub-searchPlane{
    position: absolute;
    left: 6px;
    top: -13px;
    padding: 0px 5px;
    background: white;
    /* padding: 5px; */
    color: #E35D22;
    font-size: 0.78rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
  }
  @media only screen and (min-width: 320px) and (max-width: 750px) {
    .searchPlane .custom-form-sub-searchPlane
    {
      top: -12px !important;
      font-size: 0.8rem !important;
    }
}
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error{
    color:#E35D22;
  }
 