.orange-autocomplete .MuiInputLabel-root {
  color: #E35D22;
}

.orange-autocomplete .MuiInputLabel-root:hover {
  color: #E35D22;
}

.orange-autocomplete .MuiInputLabel-root:hover {
  color: #E35D22;
}
.orange-autocomplete .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #E35D22;
}
.css-wpqx65-menu{
  z-index: 1 !important;
  }

  .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-input:focus{
    box-shadow: none !important;
  }

  .orange-border {
    border-color: orange !important; /* Ensure the border color is set to orange */
  }