/* date range left bor hide  */
.rdrDefinedRangesWrapper{
  display: none !important;
}
.css-wpqx65-menu{
z-index: 1 !important;
}
.custom-border-orange{
    /* max-width: 47rem !important; */
    background: white;
      color:black;
  }
  .custom-border-orange-field{
    background:white;
      color: white;
      border-radius: 5px;
      border: 1px solid #E35D22
  }
  .custom-form-sub-heading{
    position: absolute;
    left: 17px;
    top: -13px;
    padding: 0px 10px;

    background: white;
    /* padding: 5px; */
    color: #E35D22;
    font-size: 0.9rem;
    font-family: 'Inter',sans-serif;
    font-weight: 600;
    word-wrap: break-word;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {

    .amePopup-sub-heading{
      top: -6px;
      font-size: 1rem;
    }
    .newAircraft-filed-m{
      margin-top: 1rem;
    }
    .custom-form-sub-heading {
      text-overflow: ellipsis;
      top: -12px;
      left: 6px;
    }
  }
  .custom-form-sub-heading {
    left: 6px;
  }
  .css-wsp0cs-MultiValueGeneric {
    background-color: #E35D22;
    color: white !important;
  }
  .css-12a83d4-MultiValueRemove{
    background-color: #E35D22;
    color: white !important;
  }
  .css-13cymwt-control {
    background-color: black;
    border: black;
  }
  .css-1n6sfyn-MenuList {
    background: black !important;
    z-index: 1111;
  }
  .css-13cymwt-control{
    background: black !important;
    z-index: 1111;
    border: black !important;
  }
  .newAircraftForm{
display: grid;
gap: 30px;
row-gap: 10px;
  }
  .newAircraft .css-11qfo8z-MuiInputBase-root-MuiOutlinedInput-root {
color: black !important;
  }
  .newAircraft.orange-autocomplete .MuiInputLabel-root {
    color: #E35D22;
  }
   
  .newAircraft .orange-autocomplete .MuiInputLabel-root:hover {
    color: #E35D22;
  }
   
  .newAircraft .orange-autocomplete .MuiInputLabel-root:hover {
    color: #E35D22;
  }
  .newAircraft .orange-autocomplete .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #E35D22;
  }
  .newPilotForm{
    display: grid;
    gap: 30px;
    row-gap: 10px;
      }
      .newPilot .css-11qfo8z-MuiInputBase-root-MuiOutlinedInput-root {
    color: black !important;
      }
      .newPilot .orange-autocomplete .MuiInputLabel-root {
        color: #E35D22;
      }
       
      .newPilot .orange-autocomplete .MuiInputLabel-root:hover {
        color: #E35D22;
      }
       
      .newPilot .orange-autocomplete .MuiInputLabel-root:hover {
        color: #E35D22;
      }
      .newPilot .orange-autocomplete .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #E35D22;
      }