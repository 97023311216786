
.maxy-wid{
    max-width: 600px !important;
}
.card {
    border-radius: 0rem;
}

.gray-bg{
    background-color: #f2f2f2;
}

.field-errorMessage{
    font-size: 0.8rem;
}
.field-errorMessage-checkout{
    font-size: 0.7rem;
}
.btn-primary:not(:disabled):not(.disabled):active{
    color: #fff;
    background-color:#F16722 !important;
}
.btn-primary:focus
{
    box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active:focus{
    box-shadow: none !important;
}

.eye-icon-size{
width: 1.5rem;
}
.eye-icon-container{
    position: absolute!important;
    right: 9px;
    z-index: 11;
    display: flex;
    align-self: center;
    /* top: 0.2rem; */
}
/* @media only screen and (max-width: 600px) {
    .eye-icon-container{
        position: absolute!important;
        right: 9px;
        z-index: 11;
        top: 0.2rem;
    }
  } */
  
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    .field-errorMessage-checkout{
        font-size: 0.8rem;
    }
  }

  @media only screen and (min-width: 900px) {
    .field-errorMessage-checkout{
        font-size: 0.7rem;
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 320px) {
    .g-recaptcha {
        transform: scale(0.84);
        transform-origin:0 0;
        }
  }
  @media only screen and (min-width: 322px) and (max-width: 375px) {
    .g-recaptcha {
        transform: scale(0.99);
        transform-origin:0 0;
        }
  }
  @media only screen and (min-width: 380px) and (max-width: 425px) {
    .g-recaptcha {
        /* transform: scale(1.1); */
        padding-bottom: 1rem;
        transform-origin:0 0;
        }
  }
  @media only screen and (min-width: 426px) and (max-width: 768px) {
    .g-recaptcha {
        transform: scale(0.58);
        /* padding-bottom: 1rem; */
        transform-origin:0 0;
        }
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .g-recaptcha {
        transform: scale(0.55);
        /* padding-bottom: 1rem; */
        transform-origin:0 0;
        }
  }
  
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .g-recaptcha {
        transform: scale(1.0);

        transform-origin:0 0;
        }
  }
  @media only screen and (max-width: 500px) {
  }

  /* login When form open in mobile view */
  @media only screen and (min-width: 320px) and (max-width: 425px) {
    .form-control {
        height: calc(2rem + .75rem + 2px);
        font-size: 1rem !important;
    }
    .auth-form-heading{
        font-size: 2rem;
    }
    .custom-btn-mobile{
        font-size: 1.3rem;
    }
    .custom-btn-mobile2{
        font-size: 1rem;
    }
    .custom-text-mobile{
        font-size: 1.7rem; 
    }
    .register{
        color: #F16722;
        font-size: 1rem;
    }
    .forgot-password{
        color: #F16722;
        font-size: 1rem;
    }
    .not-a-member{
        font-size: 1.1rem;
    }
    .btn{
        /* height: 4rem; */
        height: 3rem;
    }

    .mobile-icon-size {
        padding: 0rem 1rem;
        font-size: 2rem;
    }

    .field-errorMessage{
        font-size: 1.2rem;
    }
  }

  
 
  