.ameShadow {
  border-radius: 8px;
  background: #ececec;
}
/* .form-check-input:checked {
  background-color: black; 
} */
.ameborder-bott{
  border-bottom: 0.15rem solid white;
}
.amefin{
  font-weight: 600 !important;
  font-size: 1rem;
  text-decoration: underline;
  margin-left: 0.5rem;
}
.amedata{
  font-weight: 600;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
  padding: 5px;
}

.amebgColor{
/* background-color: #F16722; */
border:1px solid #F16722 !important;
}



.amepr-3{
  padding-right: 1.5rem !important;
}
.amerp-2{
  padding-right: 2.55rem !important;
}
.amerp-4{
  padding-right: 1.3rem !important;
}
.amestart{
  border-radius: 2rem;
  border: 1.6px solid white ;
  padding: 0.5rem 1.5rem;
  font-size: 1rem  !important;
  font-weight: 500 !important;
  background-color: #F16722!important;
  color: white;
  margin-top: 1.5rem !important;
}
.amestart:hover{
  border-radius: 2rem;
  border: 1.6px solid white ;
  padding: 0.5rem 1.5rem;
  font-size: 1rem !important;
  font-weight: 500 !important;
  background-color: black!important;
  color:  #ececec !important;
}
.fw-bold{
  font-weight: 600;
  margin-top: 3px ;
  /* border-bottom: 1.6px solid white; */
  text-decoration: underline;
}
.amebr{
  border-radius: 0.2rem;
  background-color: black;
  border: 1.6px solid white !important;
}

.bt-new:hover{
  border: 0 !important;
  background-color: black !important;
}
.cta-d-hover:hover{
  background-color: #ececec !important;
  color: black !important;
}

.amecol{
  /* background-color:#F16722 !important; */
  /* border:1px solid #ececec !important; */
  border-radius: 0.1rem;
}
.amefs{
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}

.amer{
  border-radius: 0.2rem;
}
.amemar4{
  margin-top: 2.5rem ;
}