.custom-btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #e35d22;
  background-color: transparent;
  color: black;
  cursor: pointer;
}
.fw-base{
  font-weight: 500;
}
.custom-btn-Certificates {
  border-radius: 0px 5px 5px 0px;
}
.custom-btn-information {
  border-radius: 5px 0px 0px 5px;
}
.pimaxwid{
  max-width: 650px !important;
}
/*
.custom-btn:hover {
  background-color: #e35d22;
  border: 1px solid #e35d22;
  color: white;
} */
.personalInfo-btn-clicked {
  color: white !important;
  background-color: #e35d22 !important;
}
.personalInfo-popup-text{
    color: #000;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.personalInfo-popup-text-orange{
    color: #E35D22;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.personalInfo-popup-header{
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.plborderleft{
  border-left: 1px solid grey;
}

.personal-certificates{
  border-bottom: 1px solid #d2c9c9;
}

.personal-certificates .custom-pilot-colorChange{

  margin-bottom: 0px;
border-bottom: 2px solid  #E35D22;
}
.personal-certificates .sub-header-border{
  cursor: pointer;

}

/* has context menu */